.tip{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.tip .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.85);
    position: absolute;
    top: 0;
    left: 0;
}
.tip .con{
    width:1161px;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tip .pic{
    width:100%;
    height: 1701px;
}
.tip .str{
    width: 100%;
    height: 84px;
    color: white;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 208px;
    left: 0;
}
.tip .h77{
    width: 100%;
    height: 77px;
    display: flex;
    clear: both;
}
.tip .c_box{
    margin: 0 auto;
}