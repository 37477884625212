.share{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.share .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    position: absolute;
    top: 0;
    left: 0;
}
.share .con{
    width:725px;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.share .h44{
    width: 100%;
    height: 44px;
    display: flex;
    clear: both;
}
.share .c_box{
    margin: 0 auto;
}
.share .url{
    width: 725px;
    height: 1090px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
}
.imgbox{
    width: 725px;
    height: 1090px;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
}
.imgbox img{
    width: 100%;
    height: 100%;
}