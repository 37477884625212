.close_btn{
    width: 76px;
    height: 76px;
    border: 2px solid rgba(255,255,255,1);
    border-radius: 50%;
    padding:5px;
}
.close_btn .box{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
}
.close_btn .box .line{
    width: 48px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top:-1px;
    margin-left:-24px;
    background-color: rgba(255,255,255,1);
}
.close_btn .box .line:nth-child(1){
    transform: rotate(45deg);
}
.close_btn .box .line:nth-child(2){
    transform: rotate(-45deg);
}
