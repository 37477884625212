
.card_wrap{
    --w: 0.09259259259259259vw;
    width: 100%;
    height: calc(1090*var(--w));
}
.swiper_wrap{
    width: 160.5853766291142vw;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    color: white;
}
.h24{
    display: flex;
    width: 100%;
    height: calc(24*var(--w));
}
.h44{
    display: flex;
    width: 100%;
    height: calc(44*var(--w));
}
.noWin{
    position: relative;
}
.c_box{
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 5;
}
.imgbox{
    /* width: 503px;
    height: 769px; */
    width: 556px;
    height: 837px;
    position: relative;
    background-color: #142b72;
    border-radius: 26px;
}
.imgbox .card{
    /* width: 503px;
    height: 769px; */
    width: 556px;
    height: 837px;
    border-radius: 24px;
    border:1px solid white;
    box-shadow: 0 0 20px rgba(255,255,255,.8);
}
.url{
    /* width: 503px;
    height: 769px; */
    width: 556px;
    height: 837px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -438px;
    opacity: 0;
}
.b_box{
    margin: 0 auto;
}
.zw{
    width: 378px;
    height: 86px;
}
