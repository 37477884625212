body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --popbg: url(https://amex.mrm-mccann.cn/images/pop_bg.webp);
  --dialogbg: url(https://amex.mrm-mccann.cn/images/dialog_bg.webp);
  --infobg: url(https://amex.mrm-mccann.cn/images/info_bg.webp);
  --btnprev: url(https://amex.mrm-mccann.cn/images/b_pre_next.webp);
  --btnnext: url(https://amex.mrm-mccann.cn/images/b_pre_next_2.webp)
}
button{
  margin: 0;
  padding:0;
  outline: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
}
img{
  display: flex;
}
ul,li{
  list-style: none;
  padding: 0;
  margin: 0;
}
div{
  box-sizing: border-box;
}
.h24{
  height: 24px;
}
.h30{
  height: 30px;
}
.h40{
  height: 40px;
}
.h50{
  height: 50px;
}
.h60{
  height: 60px;
}
.h88{
  height: 88px;
}
.h94{
  height: 94px;
}
.h100{
  height: 100px;
}