.info{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    flex-direction: column;
}
.info .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.85);
    position: absolute;
    top: 0;
    left: 0;
}
.info .con{
    width:660px;
    height: 826px;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.info .title{
    font-size: 46px;
    color: white;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 38px;
}
.input_style{
    width: 526px;
    height: 74px;
    background-color: white;
    border-radius: 40px;
    border:0;
    outline: 0;
    padding:0 34px;
    box-sizing: border-box;
    font-size:36px;
    margin-bottom: 26px;
    color: #006fcf;
    box-shadow: inset 5px -5px 10px rgba(52, 122, 218, 0.5);
}
.textarea_style{
    width: 526px;
    height: 224px;
    background-color: white;
    border-radius: 40px;
    border:0;
    outline: 0;
    padding:22px 34px;
    box-sizing: border-box;
    font-size:36px;
    color: #006fcf;
    box-shadow: inset 5px -5px 10px rgba(52, 122, 218, 0.5);
}
.h72{
    width: 100%;
    height: 72px;
    display: flex;
    clear: both;
}
.h102{
    width: 100%;
    height: 102px;
    display: flex;
    clear: both;
}
.tip{
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    line-height: 3;
    color: white;
}
.btn_wrap{
    width: 386px;
    height: 76px;
    border-radius: 43px;
    background-image: linear-gradient(to right, #1b4696, #16336a);
    margin: 0 auto;
    box-shadow: 0 0 25px rgba(255,255,255,.6);
}
.btn_wrap .btn_con{
    width: 100%;
    height: 100%;
    border-radius: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn_wrap .btn_con img{
    display: flex;
}