.wlist{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.wlist .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    position: absolute;
    top: 0;
    left: 0;
}
.wlist .con{
    width:820px;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wlist .t_pop_wrap{
    width: 718px;
    height: 74px;
    background-color: white;
    border-radius: 40px;
    margin: 40px auto 0;
    box-shadow: inset 5px -5px 10px rgba(52, 122, 218, 0.8);
}
.wlist .t_pop{
    display: flex;
    width: 100%;
    height: 100%;
}
.wlist .n{
    color: white;
    font-size: 32px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}
.wlist .ul{
    width: 718px;
    margin: 0 auto;
    display: grid;
    color: white;
    list-style: none;
    font-size: 32px;
    grid-template-columns: 33% 33% 33%;
}
.wlist .ul li{
    flex:1
}
.wlist .pic{
    width:946px;
    height: 1141px;
}
.wlist .str{
    width: 100%;
    height: 84px;
    color: white;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 208px;
    left: 0;
}
.wlist .h77{
    width: 100%;
    height: 77px;
    display: flex;
    clear: both;
}
.wlist .close{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 36px;
    position: relative;
    z-index: 5;
}
.scroll_wrap{
    width: 100%;
    max-height: 90px;
    overflow-y: auto;
}