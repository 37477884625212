.dialog{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialog .mask{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    position: absolute;
    top: 0;
    left: 0;
}
.dialog .con{
    width:712px;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dialog .text{
    width: 100%;
    height: 166px;
    color: white;
    font-size: 42px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 2px;
    margin-top: 20px;
}
.dialog .h20{
    width: 100%;
    height: 20px;
    display: flex;
    clear: both;
}
.dialog .h64{
    width: 100%;
    height: 64px;
    display: flex;
    clear: both;
}
.btn_wrap{
    width: 386px;
    height: 76px;
    border-radius: 38px;
    background-image: linear-gradient(103deg, #1b4696,#16336a);
    margin: 0 auto;
}
.btn_wrap .btn_con{
    width: 100%;
    height: 100%;
    border-radius: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 25px rgba(255,255,255,.6);
}
.btn_wrap .btn_con img{
    display: flex;
}