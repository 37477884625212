.nav_rule{
    width: 198px;
    height: 68px;
    position: fixed;
    top: 62px;
    right: 0;
    display: flex;
    object-fit: cover;
    background-color: white;
    border-radius: 35px 0 0 35px;
    box-shadow: inset 10px -10px 27px rgba(52, 122, 218, 0.6);
}