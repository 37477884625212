.rule{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    flex-direction: column;
}
.rule .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.85);
    position: absolute;
    top: 0;
    left: 0;
}
.rule .con{
    width: 892px;
    position: relative;
    z-index: 3;
}
.pop_title{
    width: 718px;
    height: 74px;
    margin: 52px auto 0;
    font-size: 42px;
    color: #006fcf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: white;
    letter-spacing: 10px;
    border-radius: 37px;
    box-shadow: inset 5px -5px 10px rgba(52, 122, 218, 0.8);
}
.rule .title{
    margin: 0 auto;
}
.rule .close{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 36px;
    position: relative;
    z-index: 5;
}
.rule .scroll{
    width: 800px;
    height: 1487px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 auto;
}
.rule .scroll img{
    width: 100%;
}