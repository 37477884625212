.btn_mid{
    width: 532px;
    height: 92px;
    border-radius: 46px;
    border: 0;
    background-image: linear-gradient(103deg, #1b4696,#16336a);
    outline: 0;
    margin: 0 auto;
    box-shadow: 0 0 25px rgba(255,255,255,.6);
}
.btn_wrap{
    width: 100%;
    height: 100%;
    border-radius: 46px;
    color: white;
    font-size: 37px;
    font-weight: bold;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}