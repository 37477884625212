
.card_wrap{
    --w: 0.09259259259259259vw;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.card_wrap .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    position: absolute;
    top: 0;
    left: 0;
}
.swiper_wrap{
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    color: white;
}
.h44{
    display: flex;
    width: 100%;
    height: calc(44*var(--w));
}
.noWin{
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 5;
}
.c_box{
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 5;
}
.imgbox{
    width: 725px;
    height: 1090px;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
}
.imgbox img{
    width: 100%;
    height: 100%;
}
.url{
    width: 725px;
    height: 1090px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -474px;
    opacity: 0;
}
.b_box{
    margin: 0 auto;
}
.p_c_box{
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: 630px;
    left: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
}
.b_left,.b_right{
    width: 62px;
    height: 120px;
    position: absolute;
    top: 50%;
    margin-top: -60px;
    pointer-events: none;
    z-index: 5;
}
.b_left{
    left: 90px;
}
.b_right{
    right: 90px;
    transform: scaleX(-1);
}
