.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.flex_h_center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes rotateAn {
  0%{
      transform: rotate(0deg);
  }
  50%{
      transform: rotate(180deg);
  }
  100%{
      transform: rotate(360deg);
  }
}
.loadingAn{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -54px;
  margin-left: -54px;
  border-top: 8px solid rgba(255, 255, 255, .85);
  border-right: 8px solid rgba(255, 255, 255, 0.3);
  border-bottom: 8px solid rgba(255, 255, 255, 0.3);
  border-left: 8px solid rgba(255, 255, 255, 0.3);
  animation-name: rotateAn;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.btn_mini{
  width: 234px;
  height: 79px;
}

.btn_m_1{
  position: absolute;
  top: 224px;
  right: 60px;
}
.btn_m_2{
  position: absolute;
  top: 462px;
  right: 60px;
}
.btn_m_3{
  position: absolute;
  top: 714px;
  right: 60px;
}

.btn{
  font-size: 30px;
}

.h20{
  height: 20px;
}
.h38{
  height: 38px;
}
.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in;
}
.fade-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.Home,.Send,.Card,.Task,.Info{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.h_container,.s_container,.c_container,.t_container,.i_container{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(0,-50%,0);
  z-index: 5;
}
.h_btn_wrap{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 584px;
  margin-left: -291px;
}

.s_container{
  transform: translate3d(0,-33%,0);
}
.c_container{
  transform: translate3d(0,-436px,0);
}
.t_container{
  transform: translate3d(0,-304px,0);
}

.tags_area{
  width: 1110px;
  margin: 0 auto;
}
.tags_area h3{
  font-size: 40px;
  color: white;
  text-align: left;
}
.tags_wrap{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.tags_wall_wrap{
  width: 100%;
  height: 590px;
  margin-top: 66px;
  user-select: none;
  pointer-events: none;
}
.poster{
  display: flex;
}

.blessing_count{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255,255,255,1);
}
.blessing_count .t{
  font-size:50px;
}
.blessing_count .c{
  font-size:100px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.blessing_count .c span{
  font-size:128px;
  letter-spacing: 16px;
}
.blessing_count .d{
  font-size: 50px;
  display: flex;
  line-height: 1;
  align-items: baseline;
}
.blessing_count .d span{
  font-size: 80px;
  font-weight: bold;
}

.swiper{
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
}
.swiper-container {
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
}
.swiper .swiper-slide{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
  transform: scale(0.9);
}
.swiper .swiper-slide img{
  opacity: 0.5;
  transition: all 300ms;
}
.swiper .swiper-slide-active,.swiper .swiper-slide-duplicate-active{
  transform: scale(1);
  transition: all 300ms;
}
.swiper .swiper-slide-active img{
  opacity:  1;
  transition: all 300ms;
}

.h_item{
  width: 564px;
  height: 178px;
  box-shadow: 6px 6px 10px rgba(0,0,0,.5);
  position: relative;
  margin: 0 auto;
  margin-bottom: 156px;
  border: 2px solid #f9deb9;
  border-radius: 15px;
}
.h_item:last-child{
  margin-bottom: 0;
}
.h_nav_bg{
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.h_t{
  position: absolute;
  top: 0;
  left: 0;
}
.h_s_wrap{
  width: 356px;
  height: 52px;
  background-image: linear-gradient(to bottom, #edbe63,#d8a859,#edbe63);
  border-radius:30px;
  box-shadow: 3px 3px 5px rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -26px;
  left: 50%;
  margin-left: -178px;
}
.h_text{
  margin: -92px auto 260px;
  display: flex;
}
.h_sw{
  position: absolute;
  top: -50px;
}
.h_sw.s_1{
  left: 28px;
}
.h_sw.s_2{
  right: 28px;
}
.h_b{
  width: 1080px;
  height: 709px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.t_start{
  width: 100%;
  height: 100%;
  display: flex;
}
.s_title{
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-650px,0);
}
.m_card_wrap{
  width: 100%;
  height: 1090px;
  position: relative;
}
.g_b{
  position: absolute;
  bottom:0;
  left: 0;
}
.s_rule_wrap{
  width: 870px;
  position: relative;
  margin: 0 auto;
}
.s_rule_wrap.block{
  display: block;
}
.s_rule_wrap .count{
  color: white;
  font-size: 32px;
  margin-top: 20px;
}
.s_rule{
  width: 870px;
  height: 1163px;
}
.btn_start{
  width: 582px;
  height: 114px;
  overflow: hidden;
  margin: -50px auto 0;
  border-radius: 57px;
  background-image:linear-gradient(103deg, #1b4696,#16336a);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 25px rgba(255,255,255,.6);
  z-index: 3;
  position: relative;
  color: white;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 2px;
}
.btn_start.disabled{
  background-image: linear-gradient(to bottom, #b4b4b4,#868686);
}

.s_game_wrap{
  width: 902px;
  margin: 0 auto;
  position: relative;
}
.s_game_wrap.block{
  display: block;
}
.g_title{
  position: absolute;
  top: -51px;
  left: 50%;
  margin-left: -322px;
  z-index: 10;
}
.game_box{
  width: 100%;
  overflow: hidden;
}
.canvas{
  position: absolute;
  top: 0;
  left: 0;
  width: 870px;
  height: 1139px;
}
.time{
  position: absolute;
  color: #00175a;
  top: 30px;
  right: 40px;
  font-size: 64px;
  font-weight: bold;
}
.btn_box{
  width: 232px;
  height: 94px;
  background-image: linear-gradient(to bottom, #efc064,#bf8d4c);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom_btns{
  width: 740px;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0;
}

.t_header{
  margin: 0 auto;
}
.task_list{
  width: 841px;
  margin: 0 auto;
}
.task_item{
  width: 827px;
  height: 225px;
  position: relative;
  margin-bottom: 94px;
  border-radius: 28px;
  background-color: white;
  box-shadow: inset 5px -5px 10px rgba(52, 122, 218, 0.5);
}
.task_cic{
  width: 119px;
  height: 119px;
  border-radius: 50%;
  background-color: rgba(47,109,200,.2);
  position: absolute;
  top: 52px;
  left: 36px;
}
.task_text{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
.task_btn_wrap{
  width: 290px;
  height: 56px;
  background-image: linear-gradient(to right, #1b4696,#16336a);
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -28px;
  left: 50%;
  margin-left: -145px;
  box-shadow: 0 0 20px rgba(255,255,255,.8);
}
.tip{
  margin: 0 auto;
}
.c_tip{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;
}
.c_tip.open{
  margin-top: 40px;
}
.c_tip.open .snake{
  display: none;
}

.swiper-button-prev{
  width: 62px;
  height: 120px;
  left: 90px;
  background-image: var(--btnprev);
  background-size: cover;
  background-repeat: no-repeat;
}
.swiper-button-prev:after{
  content: '';
}
.swiper-button-next{
  width: 62px;
  height: 120px;
  right: 90px;
  background-image: var(--btnnext);
  background-size: cover;
  background-repeat: no-repeat;
}
.swiper-button-next:after{
  content: '';
}

/*弹框*/
.pop_wrap{
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  background-image: var(--dialogbg);
  background-size: cover;
  background-repeat: no-repeat;
}
.pop_wrap.popbg{
  background-image: var(--popbg);
  background-size: cover;
  background-repeat: no-repeat;
}
.pop_info{
  background-image: var(--infobg);
}
.pop_wrap .pop_title{
  width: 312px;
  height: 78px;
  background-color: #00175a;
  border-radius: 0 0 18px 18px;
  margin: 0 auto;
  font-size: 42px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.pop_wrap .pop_title_2{
  width: 312px;
  margin: 52px auto 0;
  font-size: 42px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  letter-spacing: 4px;
}
.pop_wrap .pop_title.w424{
  width: 424px;
}
.pop_wrap .pop_h,.pop_wrap .pop_f{
  width: calc(100% - 56px);
  height: 36px;
  border: 2px solid #f9deb9;
  border-radius: 24px 24px 0 0;
  background-color: #a12216;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.pop_wrap .pop_h{
  border-bottom: 0;
}
.pop_wrap .pop_f{
  border-radius: 0 0 24px 24px;
  border-top: 0;
}

.pop_wrap .pop_c{
  width: 100%;
}

.m_tip{
  display: flex;
  justify-content: center;
  color: white;
  font-size: 42px;
}
.m_btn_wrap{
  width: 528px;
  margin: 0 auto;
}
.empty_con{
  width: 100%;
  height: 100%;
}